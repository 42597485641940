.row {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.text-muted {
    --bs-text-opacity: 1;
    color: #6c757d !important;
    display: block;
    font-size: 12px;
}

.error-message {
    margin-bottom: 0rem;
    color: #bc2121;
    font-size: 12px;
}

.header-tab-menus .item.has-submenu.active {
    padding-right: 24px;
    border-bottom: 0 !important;
}

.header-tab-menus .item.has-submenu.active::after {
    content: "";
    position: absolute;
    right: 5px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #3f51b5;
    top: 12px;
}

.header-tab-submenus {
    overflow: auto;
    white-space: nowrap;
    border-bottom: 1px solid #cccfd1;
    box-shadow: 1px 2px 2px #efefef;
    position: relative;
    padding-left: 0px;
    clear: both;
}

.header-tab-submenus .subemenu-arrow {
    color: var(--dk-blue);
}

.header-tab-submenus .item {
    padding: 5px 0px;
    font-weight: 500;
    color: #333;
    font-size: 10px;
    cursor: pointer;
    text-decoration: none;
    margin: 0 10px;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 0.3px;
}

.header-tab-submenus .item.active {
    border: 1px solid transparent;
    border-radius: 0;
    border-bottom: 1px solid var(--dk-blue);
    font-weight: 700;
    color: var(--dk-blue);
}

.successful-message {
    color: #a7dfc6;
}

.menu-tabs .nav-item {
    border: 0;
}

.menu-tabs .nav-link {
    border: 0;
    border-bottom: 1px solid #eee;
    color: #000;
    padding-top: 0;
    font-size: 14px;
}

.menu-tabs .nav-link.active {
    border: 0;
    border-bottom: 2px solid #333;
    color: #000;
    font-weight: 700;
    letter-spacing: 0.3px;
}

.menu-tabs-tab-title {
    text-align: left;
    padding-left: 0;
    font-weight: 600;
    font-size: 16px;
    color: var(--dk-blue);
    border: 0;
    padding: 5px;
}

.menu-type-items-title {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: #333;
    border: 0;
    padding: 5px;
}

.menu-type-items {
    background: #f1f1f1;
    color: #333;
    padding: 3px 8px;
    border: 0 !important;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    line-height: 1.4;
}

.menu-type-items.active {
    border-bottom: 3px solid var(--dk-blue) !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-weight: 700;
}

.img-grid {
    min-height: 15px;
    max-height: 15px;
    max-width: 100%;
    text-align: center;
}

.img-grid img {
    max-height: 15px;
    max-width: 100%;
    object-fit: contain;
}

.soh-ipt-grp-text {
    padding: 0 5px;
    font-weight: 500;
    min-width: 40px;
    display: flex;
    justify-content: center;
    font-size: 12px;
}

.btn-add-slot {
    padding: 0;
    margin: 0;
    width: 26px;
    height: 26px;
    line-height: 1;
    font-size: 12px;
}

.day-card,
.date-card {
    border: 0;
    border-radius: 0;
    background: transparent;
    border: 1px solid #e4e4e4;
}

.day-card .card-header {
    border-bottom: 0 !important;
    border-radius: 0 !important;
}

.day-card .card-body,
.date-card .card-body {
    background: #f5f5f5;
}

.date-card .card-header {
    border-bottom: 1px solid #e5e5e5 !important;
}

.day-name {
    font-size: 0.9rem;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    top: 2px;
}

.gpnt-poupup-tabs {
    display: flex;
    justify-content: start;
    background: var(--dk-blue);
    position: relative;
}

.gpnt-poupup-tabs .nav-item,
.gpnt-poupup-tabs .nav-item:focus-visible {
    outline: none;
}

.gpnt-poupup-tabs .nav-item:first-of-type {
    min-width: 80px;
    position: relative;
}

.gpnt-poupup-tabs .nav-item:first-of-type:after,
.gpnt-poupup-tabs .nav-item:first-of-type:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.gpnt-poupup-tabs .nav-item:first-of-type:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #fff;
    border-width: 0px;
    margin-top: -10px;
}

.gpnt-poupup-tabs .nav-item:first-of-type:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: #fff;
    border-width: 8px;
    margin-top: -7px;
}

.gpnt-poupup-tabs .nav-item:last-of-type {
    position: absolute;
    right: 0;
    background: transparent;
}

.gpnt-poupup-tabs :nth-child(2) {
    background: var(--dk-blue);
    width: 25px;
    border: 0;
    border-color: transparent;
}

.gpnt-poupup-tabs .nav-item:last-of-type {
    background: #d43f3a;
}

.gpnt-poupup-tabs .nav-item:first-of-type .nav-link.disabled {
    background: transparent;
    border: 0;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0;
    position: relative;
    top: 4px;
    text-transform: uppercase;
}

.gpnt-poupup-tabs .nav-item .nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
    color: #fff !important;
    transition: none !important;
    margin-bottom: 0 !important;
}

.gpnt-poupup-tabs .nav-item .nav-link.active {
    color: var(--dk-blue) !important;
    border-color: transparent;
}

.gpnt-poupup-tabs .nav-link:hover,
.gpnt-poupup-tabs .nav-link:focus {
    isolation: unset !important;
    border-color: transparent !important;
    background: #eee;
    color: #000 !important;
}

.gpnt-poupup-tabs .nav-item:last-of-type .nav-link:hover,
.gpnt-poupup-tabs .nav-item:last-of-type .nav-link:focus {
    isolation: unset !important;
    border-color: transparent !important;
    background: transparent !important;
    color: #fff !important;
}

.select__control {
    min-height: 28px;
    padding: 0px 5px;
    font-size: 13px;
    border-radius: 3px;
    font-weight: 500;
    color: #333;
    min-height: 28px !important;
}

.select__indicator-separator {
    display: none;
}

.select__indicator {
    position: absolute;
    right: 10px;
    bottom: 3px;
    width: 20px;
}

.select__value-container {
    padding: 0 0px !important;
    min-height: 28px;
}

.select__multi-value {
    min-height: 20px;
    line-height: 1.5;
    margin: 0 5px 0 0 !important;
    position: relative;
    bottom: 0px;
    background-color: var(--dk-blue) !important;
    color: #fff;
}

.select__multi-value__label {
    padding: 3px 5px !important;
    font-size: 11px !important;
    letter-spacing: 0.5px;
    color: #fff !important;
}

.select__input-container {
    margin: 0 !important;
    min-height: 28px;
    padding: 0 !important;
}

.select__placeholder {
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 0 !important;
    margin: 0 !important;
    color: #333 !important;
}

.btn-add-variant-top {
    position: absolute;
    right: 15px;
}

.gpnt-edit-table tbody tr td,
.gpnt-edit-table thead tr th {
    padding: 5px 5px !important;
    vertical-align: middle;
    border: 1px solid #eee;
    font-size: 14px;
}

.gpnt-edit-table tbody tr td input {
    max-width: 100%;
    font-weight: 500;
    font-size: 12px;
}

.gpnt-edit-table tbody tr td .select__multi-value__label {
    font-size: 10px !important;
    line-height: 1.5 !important;
}

.prc-svc-name {
    font-weight: 500;
    font-size: 14px;
}

.card-addon {
    padding: 5px;
    border: 1px solid #eee !important;
}

.card-body-variant {
    background: #eee;
    padding-top: 8px;
    padding-bottom: 0;
}

.addon-price,
.variant-price {
    font-weight: 500;
    padding-left: 5px;
    font-size: 13px;
}

.accordion-button:focus {
    box-shadow: none;
}

.card-body-availability {
    background-color: #fff;
    padding: 0;
}

.lbl-avail-from {
    font-size: 12px;
    font-weight: 600;
    color: #337ab7;
}

.specific-day-check-label .form-check-label {
    font-size: 0.9rem;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.accordion-icon {
    position: absolute;
    right: 5px;
    max-width: 40px;
    font-size: 22px;
    top: 0;
    color: #ccc;
}

.sd-add-new-i {
    position: relative;
    bottom: 2px;
}

.i-del-day {
    position: absolute;
    right: 0;
    top: calc(50% - 5px);
    z-index: 1;
}

.gpnt-switch-row-label .form-check-label {
    font-size: 0.8rem;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    top: 2px;
}

.btn-add-day {
    font-size: 10px;
    background: #f1f1f1;
    color: var(--dk-blue);
    font-weight: 700;
}

.zone-title {
    font-weight: 700;
    padding: 5px;
}

.zone-status {
    display: flex;
    justify-content: center;
    height: 100%;
    background: #fff;
    padding-right: 61px;
    align-items: center;
}

.zone-card-ctrl {
    background: #eee;
    position: absolute;
    right: 0;
    padding: 3px 5px 0 8px;
    height: 100%;
    top: 0;
    font-size: 16px;
    cursor: pointer;
    max-width: 56px;
}

.zone-status-switch .form-check-label {
    color: #000;
    font-weight: 600;
    position: relative;
    bottom: 2px;
    font-size: 14px;
}

.gpnt-plain-text {
    font-size: 0.8rem;
    font-weight: 600;
}

.zone-footer {
    padding: 0px 5px;
}

.zone-footer-i {
    position: relative;
    bottom: 3px;
}

.zone-table {
    margin: 0;
}

.zone-table tbody tr td {
    font-size: 12px !important;
    border: 0 !important;
}

.zone-map {
    min-height: calc(100vh - 200px);
}

.zone-card {
    cursor: pointer;
}

.zone-edit-i {
    position: relative;
    top: 2px;
    right: -8px;
}

.zone-active {
    box-shadow: 0px 1px 0px 2px #6c757d;
    border: 1px solid #b9b9b9;
}

.zone-active .zone-active-i {
    display: block !important;
    position: relative;
    top: 5px;
    right: 8px;
    color: #fff;
    font-size: 14px;
}

.menu-pricing-sec .menu-price-card:last-of-type .card-footer .menu-price-add-variant {
    display: block !important;
}

.day-card:nth-of-type(odd) {
    background-color: transparent;
}

.day-card:nth-of-type(even) {
    background-color: #e1e1ff;
}

.text-theme {
    color: var(--dk-blue);
}

.payment-opt-switch {
    border-right: 2px solid #ececec;
    padding-right: 15px;
    min-width: 170px;
    font-size: 14px;
}

.chk-pmt-opt-service {
    color: #000;
    font-size: 12px;
}

.pmt-key-label {
    font-weight: 600;
    color: var(--dk-blue) !important;
}

.chk-table .form-check-input {
    font-size: 14px;
    margin: 0;
    height: 1.2em;
    width: 1.2em;
}

.disabled-checkbox .dx-checkbox-icon {
    background-color: #ddd;
    border: 1px solid #aba9a9;
}

.ws-clr-crd {
    background: #e2f6ff14;
    border: 1px solid #cac0c0;
    margin-bottom: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.ws-clr-head {
    font-weight: 600;
}

.ws-gpnt-sep {
    margin: 8px 0 !important;
    border: 1px solid #b1abab;
}

.ws-form-label {
    color: #7e7e7e;
}

.mi-crd-hdr {
    background-color: rgb(213 235 255 / 4%) !important;
    text-align: center;
}

.shop-status-sec {
    font-size: 10px;
    position: relative;
    left: 40px;
    width: 216px;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
}

.lbl-shop-online {
    padding-left: 5px;
    color: green !important;
    font-weight: 800 !important;
}

.lbl-shop-offline {
    padding-left: 5px;
    color: red !important;
    font-weight: 800 !important;
}

.gpnt-sep-menu-type {
    margin: 10px 0 10px !important;
    border: 1px solid #c8c8c8;
}

.btn-viewall-variant {
    padding: 4px 10px;
    font-size: 12px;
}

.choose-combo-item-popup .modal-header {
    padding: 8px;
}

.combo-name-box {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    max-width: 410px;
}

.dx-master-detail-row .dx-master-detail-cell {
    padding: 8px;
}

.gpnt-color-box {
    position: relative;
    top: 5px;
}

.icon-shop-status {
    position: absolute;
    top: 15px;
    font-size: 12px;
    left: -18px;
}

.icon-shop-status.online {
    color: green;
}

.icon-shop-status.offline {
    color: red;
}

.btn-copy-price-default {
    margin-top: 8px !important;
}

.btn-copy-price {
    font-size: 10px;
    padding: 2px 5px;
    margin: auto;
    font-weight: 700;
    letter-spacing: 0.5px;
    background: #eee;
    color: var(--dk-blue);
    border-radius: 0;
}

.highlight-default-row {
    background: #fafafa;
}

.price-grid-col {
    width: 123px;
    min-width: 123px;
    max-width: 123px;
    text-align: left;
}

.price-not-setup .form-check-input {
    background-color: #c2c2c2;
}

.prices-table th {
    border: 0;
}

.form-check-prices-base .form-check-label,
.form-check-prices-base.form-check-label {
    font-size: 18px;
    top: 0px;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
}

.form-check-prices {
    margin: 0;
}

.form-check-prices .form-check-label {
    font-size: 18px;
    top: -2px;
}

.td-cpy-prices {
    max-width: 124px;
}

#comboDetailsVariantGrid tr:not(.dx-header-row) td::before {
    content: "\f067";
    font: 12px/12px DXIcons;
    color: transparent;
    height: 20px;
    width: 20px;
    border: 1px solid #ccc;
    margin-right: 8px;
    text-align: center;
    line-height: 20px;
}

#comboDetailsVariantGrid tr:not(.dx-header-row).dx-selection td::before {
    content: "\f005";
    font: 12px/1 DXIcons;
    color: var(--dk-blue);
    height: 20px;
    width: 20px;
    border: 1px solid #ccc;
    margin-right: 8px;
    text-align: center;
    line-height: 20px;
}

.od-pname {
    font-size: 14px;
    font-weight: 600;
}

.od-qty-info {
    font-size: 14px;
    font-weight: 600;
}

.od-price-info {
    font-size: 14px;
    font-weight: 600;
}

.od-item-header {
    font-size: 16px;
    padding: 4px 4px;
    background: #f6f6f6;
    border: 1px solid #eee;
}

.od-os-name {
    font-weight: 600;
    font-size: 12px;
}

.od-addon-name {
    font-size: 12px;
    font-weight: 600;
    color: #697074;
    padding-left: 10px;
    position: relative;
}

.od-addon-name::before {
    border-color: rgba(194, 225, 245, 0);
    border-width: 8px;
    margin-top: 0;
    left: 0;
    top: 6px;
    border: solid #e1e0e0;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-radius: 100%;
}

.od-addon-pill {
    font-size: 9px;
    padding: 0 5px;
    position: relative;
    left: 5px;
    line-height: 1.8;
    height: 15px;
    min-width: 22px;
    background: #3a3a3a !important;
}

.pd-tab {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    background: #fff;
    border: 1px solid #fafafa;
    border-bottom: 3px solid #fafafa;
    cursor: pointer;
}

.pd-tab.active {
    font-weight: 600;
    color: var(--dk-blue);
    border-bottom: 3px solid var(--dk-blue);
}

.pd-tab:first-of-type {
    border-right: 0;
}

.pd-os-sep {
    border: 1px solid #eee;
}

.placed-header {
    background: #3f51b5 !important;
}

.confirmed-header {
    background: #8bc34a !important;
}

.ready-header {
    background: #3195a5 !important;
}

.completed-header {
    background: #3e884f !important;
}

.cancelled-header {
    background: #c43d4b !important;
}

.refunded-header {
    background: #3e884f !important;
}

.ontheway-header {
    background: #ffc107 !important;
}

.un-paid-header {
    background: #767676 !important;
}

.order-header {
    padding: 8px 10px;
    color: #fff;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-weight: 600;
}

.order-body {
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
}

.order-alert {
    border: 1px solid #ccc;
    border-radius: 0;
}

.order-alert-body {
    padding: 8px;
    cursor: pointer;
    min-height: 105px;
}

.order-info {
    font-size: 12px;
}

.btn-default {
    background: #e9e9e9;
}

.order-card-btn {
    width: 100%;
    border-radius: 0;
}

.od-card-header {
    background: #ffffff !important;
    color: #000;
}

.gpnt-pp-table thead,
.gpnt-pp-table tbody,
.gpnt-pp-table tfoot,
.gpnt-pp-table tr,
.gpnt-pp-table td {
    padding: 0 5px;
    vertical-align: middle;
    font-weight: 600;
}

.gpnt-pp-table th,
.gpnt-child-table th {
    padding: 5px;
    color: #000;
    font-weight: 600;
    font-size: 11px;
}

.gpnt-child-table thead,
.gpnt-child-table tbody,
.gpnt-child-table tfoot,
.gpnt-child-table tr,
.gpnt-child-table td,
.gpnt-child-table th {
    padding: 0 5px;
    vertical-align: middle;
    font-weight: 600;
}

.gpnt-child-table td.has-input,
.gpnt-pp-table td.has-input {
    padding: 0 !important;
}

.gpnt-child-table,
.gpnt-pp-table {
    font-size: 11px;
}

.gpnt-pp-table .form-control,
.gpnt-pp-table .form-select {
    border-radius: 0 !important;
    border: 1px solid #f3f3f3;
    font-size: 11px;
}

.gpnt-child-table .form-control,
.gpnt-child-table .form-select {
    border-radius: 0 !important;
    border: 1px solid #f3f3f3;
    font-size: 11px;
}

.highlight-field {
    border: 1px solid #bc2121;
}

.rpt-card {}

.rpt-card .card-header {
    background-color: transparent !important;
    text-align: center;
    padding: 15px;
    border: 1px solid #e5e5e5;
}

.rpt-icon {
    font-size: 2.5rem;
    color: var(--dk-blue);
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 8px;
}

.rpt-name {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--dk-blue);
}

.rpt-card-active {
    background-color: #0d6efd;
    color: #fff;
}

.rpt-card-active .rpt-icon,
.rpt-card-active .rpt-name {
    color: #fff;
}

.rpt-search-card {
    background: #0dcaf02e;
    padding: 20px;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.rpt-box-header {
    background: #0dcaf01a;
    color: #000;
}

.gpnt-daterange {
    max-width: 225px;
}

.gpnt-daterange input {
    line-height: 28px;
    height: 28px;
    padding: 0px 5px;
    font-size: 13px;
    font-weight: 500;
    color: #333;
}

.adv-card {
    width: 25rem;
    height: 150px;
    max-height: 150px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-height: 150px;
    margin-bottom: 50px;
}

.adv-img {
    object-fit: contain;
    max-height: 100%;
}

.adv-card-body {
    max-height: 150px;
    min-height: 150px;
}

.adv-card-footer {
    background: #e3e3e3;
}

.maxwidth-dialog {
    max-width: 1200px;
}

.chk-group-icon {
    position: absolute;
    left: -4px;
    font-size: 21px;
    bottom: 1px;
    color: #44444470;
}

.chk-group-icon-sd {
    position: absolute;
    left: -4px;
    font-size: 21px;
    bottom: 4px;
    color: #44444470;
}

.chk-group-items {
    padding-left: 2.4rem;
}

.show-toggle-div {
    display: none;
}

.show-toggle-div.visible {
    display: block;
}

.sub-role-box {
    max-width: 70px;
    display: flex !important;
    flex-direction: row;
}

.sub-role-box .show-toggle-div.visible {
    display: inline-flex;
}

.staff-discount-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
}

.sd-dep-name-label {
    width: 200px;
    margin-right: 30px;
}

.sd-dep-dicount {
    width: 90px;
}

.sd-dep-dicount::after {
    content: "%";
}

.sd-subdep-name-label {
    width: 200px;
    margin-right: 12px;
    font-size: 11px;
}

.sd-perc {
    margin-left: 5px;
    font-size: 12px;
    font-weight: 700;
    margin-right: 5px;
}

.prod-addon-container {
    padding: 8px 0;
}

.prod-addon-list li {
    font-size: 12px;
}

#controlled-tab-example-tab-print {
    background: inherit;
}

#controlled-tab-example-tab-print:hover {
    background: #fff;
    color: var(--dk-blue);
}

.grid-back-button .dx-button {
    background: transparent;
    position: relative;
    bottom: 7px;
    border-radius: 100% !important;
    border-color: var(--dk-blue) !important;
}

.grid-back-button .dx-button-has-icon .dx-icon {
    width: 12px !important;
    height: 12px !important;
    color: var(--dk-blue) !important;
    font-size: 17px !important;
}

.ql-editor {
    height: 130px !important;
    /* Set height for the Quill editor */
}

.btn-add-link {
    height: 30px;
    line-height: 15px;
    border-radius: 0;
}

.img-gallery {
    height: 150px;
    max-height: 150px;
    object-fit: cover;
}

.del-gallery {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #ccc;
    width: 30px;
    height: 30px;
    text-align: center;
}

.hide-accordion-arrow .accordion-button::after {
    background-image: none !important;
}

.form-check-prices-base {
    top: 0.1em !important;
}

.template-type-header {
    border: 1px solid #ccc;
    border-bottom: 0;
    background: #2a7cb4 !important;
    color: #fff !important;
}

.tab-pane .ql-toolbar.ql-snow {
    background: #e3f3ff;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
    border-bottom: 0;
}

.gpnt-modal-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    font-size: 1rem;
    height: 38px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
    padding-left: 12px;
}

.custom-tool-title {
    margin-right: 8px;
    font-size: 10px;
    padding-top: 4px;
    font-weight: 600;
    color: #2a7cb4;
    text-transform: uppercase;
    max-width: 50px;
}

.btn-custom-tool {
    background: #ffffff !important;
    font-size: 10px;
    color: #444;
    font-weight: 600;
    border: 1px solid #ccc !important;
    height: auto !important;
    text-transform: capitalize;
    margin: 0;
    padding: 2px 10px !important;
    width: auto !important;
    border-radius: 4px;
}

.btn-tmp-font {
    margin-bottom: 8px;
    margin-right: 8px;
}

.btn-tmp-field {
    margin-bottom: 4px;
    margin-right: 4px;
}

.img-delete {
    background-color: #fff;
    border-radius: 0 0 4px 0;
    color: red !important;
    cursor: pointer;
    font-size: 12px;
    height: 20px;
    position: absolute;
    text-align: center;
    text-decoration: underline;
    top: 0;
    width: 20px;
}

.fa-trash:before {
    content: "\f1f8";
}

.tbody-fs {
    font-size: 14px;
}

.cursor-pointer{
    cursor: pointer;
}